<template>
  <div class="orders">
    <header>
      <nav class="filters">
        <a
          href="#"
          :class="{ active: orderStore.currentFilter === 'all' }"
          @click.prevent="setFilter('all')"
          >Toutes</a
        >
        <a
          href="#"
          :class="{ active: orderStore.currentFilter === 'pending' }"
          v-if="userStore.user.role !== 'seller'"
          @click.prevent="setFilter('pending')"
          >En attente</a
        >
        <a
          href="#"
          :class="{ active: orderStore.currentFilter === 'validated' }"
          v-if="userStore.user.role !== 'seller'"
          @click.prevent="setFilter('validated')"
          >Validée</a
        >
        <a
          href="#"
          :class="{ active: orderStore.currentFilter === 'fulfilled' }"
          @click.prevent="setFilter('fulfilled')"
          >Expédiée</a
        >
        <a
          href="#"
          :class="{ active: orderStore.currentFilter === 'unpaid' }"
          @click.prevent="setFilter('unpaid')"
          >Impayée</a
        >
        <!-- <a href="#" :class="{ active: orderStore.currentFilter === 'closed' }">Fermée</a> -->
      </nav>
      <section class="search">
        <div>
          <FormSearch
            title="forms.label.search"
            placeholder="forms.placeholder.filter_order"
            @input="onSearchInput"
          />
          <div v-if="searchWarning" class="warning">{{ searchWarning }}</div>
        </div>
        <!-- <a class="btn">
          <IconLibrary name="sort" color="white" size="sm" />
          Trier
        </a> -->
      </section>
    </header>
    <div v-if="orderStore.orders.length < 1" class="empty-orders">
      <EmptyState>
        <IconLibrary name="order" size="xxl" />
        <h4>No Orders</h4>
        <p>
          Uh-oh, looks like you haven't any orders yet!<br />
          To get started, click on the following link
          <a href="#">to create your first order</a>.
        </p>
        <p>
          <small>
            Once created, it will appear here and you can start managing your order right away.
          </small>
        </p>
      </EmptyState>
    </div>
    <div v-else>
      <router-link
        v-for="order in orderStore.getSortedOrders"
        class="order"
        :class="{
          'has-new-items': hasNewProducts(order),
          'fully-denied': isFullyDenied(order)
        }"
        :key="order._id"
        :to="`/orders/${order._id}`"
      >
        <FormCheckbox />
        <div class="name">{{ order.to_address.full_name }}</div>
        <div class="ref">
          <FlagLibrary :code="order.to_address.country" /> {{ order.identifier }}
        </div>
        <div class="total">
          <MoneyFormat :amount="getTotal(order.products)" currency="EUR" />
        </div>
        <div class="buyer">{{ order.to_address.identifier }}</div>
        <div v-if="hasTags" class="tags"><!-- Tag Component Here --></div>
        <FormattedDate :date="order.created_at" />
        <div class="payment">
          <span class="tag paid">
            <IconLibrary name="status" :color:="statusColor(order.is_paid)" size="xs" />
            {{ order.is_paid ? 'Paid' : 'Unpaid' }}
          </span>
        </div>
        <div class="fulfillment">
          <span class="tag unfulfilled">
            <IconLibrary name="status" :color="statusColor(order.is_fulfilled)" size="xs" />
            {{ order.is_fulfilled ? 'Fulfilled' : 'Unfulfilled' }}
          </span>
        </div>
        <div class="actions">
          <!-- <IconLibrary v-if="order.state !== 'purchased'" name="validate" size="sm" />
        <IconLibrary v-if="order.state !== 'purchased'" name="cancel" size="sm" /> -->
          <!-- <IconLibrary name="delete" color="red" size="sm" class="delete-margin" /> -->
        </div>
        <!-- <div v-if="isBatched" class="batch">Batch Component Here</div> -->
      </router-link>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { getTotal } from '../../libs/utils';
import { useOrderStore } from '../../stores/orders.js';
import { useUserStore } from '../../stores/user.js';
import FormattedDate from '../Shared/FormattedDate.vue';
import FormCheckbox from '../Shared/Forms/FormCheckbox.vue';
import MoneyFormat from '../Shared/MoneyFormat.vue';
import FormSearch from '../Shared/Forms/FormSearch.vue';
import IconLibrary from '../Shared/IconLibrary.vue';
import FlagLibrary from '../Shared/FlagLibrary.vue';
import EmptyState from '../Shared/EmptyState.vue';

const orderStore = useOrderStore();
const userStore = useUserStore();
const statusColor = (status) => (status ? 'green' : 'yellow');

const setFilter = (filter) => {
  orderStore.setFilter(filter);
};

const searchWarning = ref('');

function debounce(func, wait) {
  let timeout;
  return function (...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
}

const onSearch = debounce((query) => {
  if (query.length < 3 && query.length > 0) {
    searchWarning.value = 'Please enter at least 3 characters.';
    return;
  }
  searchWarning.value = '';
  orderStore.setSearchQuery(query);
}, 300);

const onSearchInput = (event) => {
  const query = event.target.value;
  onSearch(query);
};

// const setPage = (page) => {
//   orderStore.setPage(page);
// };

const hasNewProducts = (order) => {
  if (userStore?.user?.role === 'seller') {
    return order.products.some((product) => !product.is_fulfilled);
  }
  return order.products.some((product) => !product.is_validated && !product.is_denied);
};

const isFullyDenied = (order) => {
  return order.products.every((product) => product.is_denied);
};

defineProps({
  type: { type: Object, required: true, default: 'list' }
});
</script>

<style lang="scss" scoped>
:deep(.form-checkbox) {
  padding: 0 0;
}
nav.filters {
  display: flex;
  align-items: center;
  gap: 16px;
  border-bottom: 1px solid $gainsboro;
  a {
    text-decoration: none;
    font-size: 90%;
    color: #444444;
    padding: 12px 8px;
    margin-bottom: -1px;
    border-bottom: 3px solid transparent;
  }
  a.active {
    border-bottom: 3px solid $brand-blue;
  }
  a:hover {
    border-bottom: 3px solid $gainsboro;
  }
}
section.search {
  display: flex;
  gap: 20px;
  margin: 20px 0;
}

section.search > *:first-child {
  width: 100%;
  height: 70px;
}

section.search .warning {
  color: $brand-corail;
  margin-left: 30px;
  margin-top: 5px;
}
a.order {
  display: grid;
  grid-template-columns: 35px 1.35fr 0.65fr 150px 110px 110px;
  grid-template-rows: 1fr 1fr;
  gap: 0px 20px;
  height: 65px;
  width: 100%;
  color: $brand-deep-purple;
  grid-template-areas:
    'checkbox name buyer date payment fulfillment'
    'checkbox ref buyer total payment fulfillment';
  padding: 10px 15px;
  align-items: center;
  background-color: $white;
  text-decoration: none;
  transition: background-color $base-speed-fast ease, border-left-color $base-speed-fast ease;
  border-left: 4px solid transparent;
}
a.order.tags {
  grid-template-areas:
    'checkbox name date shipping'
    'checkbox ref tags tags';
}
a.order.batch {
  grid-template-columns: 40px 1.2fr 1.8fr;
  grid-template-areas:
    'checkbox name batch'
    'checkbox ref batch';
  .date,
  .shipping,
  .tags {
    display: none;
  }
  .batch {
    display: block;
  }
}
a.order:nth-of-type(even) {
  border-top: 1px dashed rgba($brand-light-purple, 0.75);
  border-bottom: 1px dashed rgba($brand-light-purple, 0.75);
}
a.order:last-child {
  border-bottom: none;
}
a.order:hover {
  cursor: pointer;
  background-color: rgba($brand-mint, 0.05);
}
a.order.router-link-active {
  background-color: rgba($brand-light-purple, 0.4);
  color: $white;
}

// Grid Area
.form-checkbox {
  grid-area: checkbox;
  text-align: center;
}
.shop {
  grid-area: shop;
  display: flex;
  justify-content: center;
}
.name {
  grid-area: name;
}
.ref {
  grid-area: ref;
  display: flex;
  align-items: center;
}
.tags {
  grid-area: tags;
}
.date {
  grid-area: date;
}
.shipping {
  grid-area: shipping;
}
.batch {
  grid-area: batch;
}
.payment {
  grid-area: payment;
}
.fulfillment {
  grid-area: fulfillment;
}
.actions {
  grid-area: actions;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  .icon-wrapper {
    cursor: pointer;
    transition: transform 150ms ease-in-out;
    &:hover {
      transform: scale(1.25);
    }
  }
}
.buyer {
  grid-area: buyer;
}
.total {
  grid-area: total;
}
.payment,
.fulfillment {
  text-transform: capitalize;
  .tag {
    padding: 3px 14px;
    border-radius: 100px;
    font-size: 90%;
    display: inline-flex;
    align-items: center;
  }
  .tag.fulfilled,
  .tag.paid {
    background-color: $brand-light-mint;
  }
  .tag.unfulfilled,
  .tag.unpaid {
    background-color: $brand-light-indigo;
  }
  .tag.pending {
    background-color: $brand-light-gold;
  }
}

a.order.has-new-items {
  border-left-color: $brand-blue;
}

a.order.fully-denied {
  border-left-color: $brand-corail;
}

@media (max-width: 767px) {
  a.order {
    display: grid;
    grid-template-columns: 1.35fr 0.65fr;
    grid-template-rows: 1fr 1fr 1fr;
    height: auto;
    font-size: 0.85rem;
    line-height: 1.1;
    gap: 0 0.5rem;
    grid-template-areas:
      'name name'
      'ref total'
      'payment fulfillment';
  }
  .buyer,
  .actions,
  .date,
  .form-checkbox {
    display: none;
  }
}
</style>
