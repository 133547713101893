<template>
  <nav class="breadcrumb">
    <button type="button" class="btn btn-ghost" @click="goBack">
      <IconLibrary name="back" size="xs" />
      {{ $t('ui.back') }}
    </button>
  </nav>
  <section class="hero">
    <h2>
      {{ collection.title }}
    </h2>
    <p v-if="collection.description">{{ collection.description }}</p>
  </section>

  <section class="content">
    <section class="products">
      <router-link
        v-for="product in products"
        :key="product._id"
        :to="`/shop/products/${product._id}`"
      >
        <img :src="product.images?.[0]" :alt="product.name" />
        <div class="content-wrapper">
          <p>
            <small>{{ product.vendor || product.seller_name || 'In-House' }}</small>
          </p>
          <h6>{{ product.name }}</h6>
        </div>
        <MoneyFormat :amount="product.variants[0].price" currency="EUR" />
      </router-link>
    </section>
  </section>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useProductStore } from '../stores/products.js';
import { usePersistCart } from '../composables/usePersistCart';
import { storeToRefs } from 'pinia';
import MoneyFormat from '../components/Shared/MoneyFormat.vue';
import IconLibrary from '../components/Shared/IconLibrary.vue';
import { useCollectionStore } from '../stores/collections.js';

const productStore = useProductStore();
const collectionStore = useCollectionStore();
const route = useRoute();
const router = useRouter();

const collection = ref({});

const goBack = () => {
  router.back();
};

const { getSortedProducts } = storeToRefs(productStore);
const products = getSortedProducts;
usePersistCart();

onMounted(async () => {
  try {
    collection.value = await collectionStore.fetchCollection(route.params.slug);
    await productStore.fetchCollectionProducts({
      type: collection.value.type,
      payload: collection.value.products || collection.value.conditions
    });
  } catch (e) {
    console.error(e);
  }
});
</script>

<style lang="scss" scoped>
.hero {
  width: 70%;
  text-align: center;
  margin-inline: auto;
  img {
    border-radius: $base-radius;
    width: 100%;
    box-shadow: 0 0 0.5rem rgba($black, 0.1);
  }
}

.hero h2 {
  text-align: center;
  font-weight: $font-weight-black;
  text-transform: uppercase;
  padding: 0.5rem 0;
  font-size: 1.35rem;
}

.filters {
  margin: 0;
  border-radius: $base-radius;
  color: $brand-dark-grey-purple;
  h4 {
    display: flex;
    color: $brand-indigo;
    border-bottom: 1px solid $brand-light-purple;
    border-top: 1px solid $brand-light-purple;
    font-size: 1.25rem;
    @include padding('xs', 'block');
  }
}

.products {
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
  grid-auto-rows: 225px auto auto;
  gap: 0.25rem 1.5rem;
  margin: 50px 0;
}
.products > a,
.products > article {
  display: grid;
  grid-row: span 3;
  grid-template-rows: subgrid;
  margin-bottom: 2rem;
  transition: transform 150ms ease-in-out, stroke 150ms ease;
  background-color: $white;
  border-radius: $base-radius;
  box-shadow: 0px 0px 8px rgba($black, 0.1);
  padding: 1rem 0.75rem;
  text-align: center;
  &:hover {
    transform: scale(1.05);
    cursor: pointer;
    :deep(svg) {
      stroke: $brand-blue;
    }
  }
  small {
    color: $brand-grey;
  }
  img {
    overflow: hidden;
    // border: 1px solid darken($brand-light-indigo, 5%);
    border-radius: 5px;
    object-fit: contain;
    aspect-ratio: 1;
    height: 100%;
    width: 100%;
  }
  .content-wrapper {
    p {
      margin: 0;
      color: $brand-blue;
    }
  }
}
</style>
