<template>
  <div id="page">
    <header>
      <nav>
        <router-link to="/shop" class="logo">
          <img v-if="userStore.user?.logo" :src="`data:image;base64, ${userStore.user?.logo}`" />
          <img v-else src="/logos/logo-shop-black.svg" alt="nuteam online shop" />
        </router-link>
        <router-link to="/shop/cart" class="notifications">
          <IconLibrary name="order" size="sm" />
          <span class="tag count">{{ cartStore.getCount }}</span>
        </router-link>
        <router-link to="/shop/orders">
          <IconLibrary name="package" size="sm" />
        </router-link>
        <router-link
          v-if="userStore.user?.role === 'buyer'"
          :to="`/shop/profile/${userStore.user?._id}`"
        >
          <IconLibrary name="profile" size="sm" />
        </router-link>
        <router-link to="/logout">
          <IconLibrary name="logout" size="sm" />
        </router-link>
      </nav>
    </header>

    <nav class="category-nav">
      <div class="category-dropdown" @mouseover="showDropdown" @mouseleave="hideDropdown">
        <div class="category">
          <IconLibrary name="menu" size="sm" />{{ $t('ui.shop_by_category') }}
        </div>
        <transition name="fade">
          <div class="dropdown-options" v-show="isDropdownOpen">
            <router-link
              v-for="(category, index) in categoryStore.categories"
              :key="index"
              :to="`/shop/categories/${category.slug}`"
            >
              <IconLibrary name="product" size="xs" />
              {{ category.name }}
            </router-link>
          </div>
        </transition>
      </div>
      <ProductSearch class="products-search" :showFilters="false" />
    </nav>
    <main>
      <router-view />
    </main>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useCartStore } from '../stores/cart.js';
import { useUserStore } from '../stores/user.js';
import { useCategoryStore } from '../stores/category.js';
import { usePersistCart } from '../composables/usePersistCart';
import IconLibrary from '../components/Shared/IconLibrary.vue';
import ProductSearch from '../components/Products/ProductSearch.vue';
const cartStore = useCartStore();
const userStore = useUserStore();
const categoryStore = useCategoryStore();
usePersistCart();

const isDropdownOpen = ref(false);

const showDropdown = () => (isDropdownOpen.value = true);
const hideDropdown = () => (isDropdownOpen.value = false);
</script>

<style lang="scss" scoped>
.tag.count {
  left: 0;
}
.profile {
  display: flex;
  align-items: center;
  gap: 20px;
}
.products-search {
  width: 100%;
}
header {
  @include padding('md', 'inline');
  nav {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1.25rem;
  }
  nav > a:first-child {
    margin-right: auto;
  }
}
header .logo {
  width: 180px;
  height: 80px;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: left;
  }
}
h6 {
  font-weight: $font-weight-light;
  margin: 0 0 10px 0;
}
.corail {
  color: $brand-corail;
}

.category-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $brand-dark-purple;
  color: $brand-light-grey;
  margin-bottom: 2rem;
  gap: 3rem;
  @include padding('lg', 'inline');
}
.category {
  display: grid;
  grid-template-columns: 24px 1fr;
  gap: 0.5rem;
  align-items: center;
  background-color: $brand-indigo;
  cursor: pointer;
  transition: background-color 200ms ease-in-out;
  @include padding('lg', 'inline');
  @include padding('md', 'block');
  &:hover {
    background-color: lighten($brand-indigo, 10%);
  }
}
.category-dropdown {
  flex-shrink: 0;
}
.dropdown-options {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  min-width: 225px;
  max-height: 300px;
  z-index: 10;
  background-color: $white;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border: 1px solid $brand-indigo;
  border-top: none;
  overflow-y: auto;
  @include padding('md');
  box-shadow: 0 0 0.5rem rgba($black, 0.2);
  a {
    display: flex;
    text-transform: uppercase;
    align-items: center;
    font-size: 0.8rem;
    font-weight: $font-weight-bold;
    color: $brand-dark-purple;
    &:hover {
      color: $brand-corail;
    }
  }
  .router-link-active {
    color: $brand-corail;
    pointer-events: none;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 250ms ease, transform 250ms ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateY(5px);
}

.fade-enter-to,
.fade-leave-from {
  opacity: 1;
  transform: translateY(0px);
}
</style>
