<template>
  <div class="money">
    {{ formattedValue }}
  </div>
</template>

<script setup>
import { computed } from 'vue';
import currency from 'currency.js';

const currencyMap = {
  EUR: '€',
  USD: '$',
  CHF: 'CHF'
};

const props = defineProps({
  amount: {
    type: [Number, String],
    required: true
  },
  currency: {
    type: String,
    required: true
  },
  showSymbol: {
    type: Boolean,
    default: true
  }
});

const currencySymbol = currencyMap[props.currency];

const formattedValue = computed(() => {
  const numAmount = typeof props.amount === 'string' ? parseFloat(props.amount) : props.amount;
  return currency(numAmount, {
    symbol: props.showSymbol ? currencySymbol : ''
  }).format();
});
</script>

<style scoped lang="scss">
.money {
  display: inline-block;
}
</style>
