<template>
  <div id="page">
    <main>
      <header>
        <h2>{{ productId ? $t('products.edit.title') : $t('products.new.title') }}</h2>
        <div class="actions">
          <router-link to="/products" class="btn btn-ghost btn-lg">
            <IconLibrary name="back" size="sm" />
            {{ $t('ui.back') }}
          </router-link>
          <div class="btn btn-lg" @click="onSubmit">
            <IconLibrary name="download" color="white" size="sm" />
            {{ $t('ui.submit') }}
          </div>
        </div>
      </header>
      <div v-if="errorMessage" class="error-message">
        {{ $t(errorMessage) }}
      </div>
      <form @submit.prevent="onSubmit" id="add-product">
        <main>
          <section>
            <div class="boxed">
              <h6>{{ $t('products.info.title') }}</h6>
              <FormInput title="forms.label.title" v-model="form.name" />
              <FormTextArea title="forms.label.description" v-model="form.description" />
            </div>
            <div class="boxed">
              <h6>{{ $t('products.multimedia.title') }}</h6>
              <FormUpload v-model="form.images" />
            </div>
          </section>
          <section>
            <div class="boxed">
              <h6>{{ $t('products.type.title') }}</h6>
              <FormInput title="forms.label.category" v-model="form.category" />
              <!-- <FormInput title="forms.label.collection" v-model="form.collections" /> -->
              <FormInput title="forms.label.tags" v-model="form.tags" />
            </div>
            <div v-if="userRole === 'director'" class="boxed">
              <h6>{{ $t('products.rules.title') }}</h6>
              <FormInput
                :disabled="!isProductOwner"
                title="forms.label.increment"
                v-model="form.quantity_rules.increment"
              />
              <FormInput
                :disabled="!isProductOwner"
                title="forms.label.min"
                v-model="form.quantity_rules.min"
              />
              <FormInput
                :disabled="!isProductOwner"
                title="forms.label.max"
                v-model="form.quantity_rules.max"
              />
            </div>
          </section>
        </main>
        <aside>
          <div class="boxed">
            <h6>{{ $t('products.options.title') }}</h6>
            <ul class="options" v-if="form.options?.length > 0">
              <li v-for="option in form.options">
                <div class="option-name">{{ option.name }}</div>
                <div class="flex">
                  <span v-for="value in option.values" class="tag">{{ value }}</span>
                </div>
              </li>
            </ul>
            <ProductOptions v-model="form.options" v-if="isProductOwner" />
            <br /><br />
            <h6>{{ $t('products.variants.title') }}</h6>
            <!-- <hr /> -->
            <ul :class="[`role-${userRole}`, 'variants']">
              <li v-for="variant in variants" class="variant">
                <FormInput
                  title="forms.label.variant"
                  v-model="variant.title"
                  :class="[`variant-${variant.status}`, 'variant-title']"
                />
                <FormInput
                  title="forms.label.sku"
                  v-model="variant.sku"
                  :disabled="!isProductOwner"
                  :class="[`variant-${variant.status}`, 'variant-sku']"
                />
                <FormPriceInput
                  title="forms.label.price"
                  v-model="variant.price"
                  :class="[`variant-${variant.status}`, 'variant-price']"
                />
                <FormPriceInput
                  title="forms.label.purchase-price"
                  v-model="variant.purchase_price"
                  v-if="userRole === 'director' && !isProductOwner"
                  :disabled="!isProductOwner"
                  :class="[`variant-${variant.status}`, 'variant-purchase-price']"
                />
                <FormNumberInput
                  title="forms.label.quantity"
                  v-model="variant.quantity"
                  :disabled="!isProductOwner"
                  :class="[`variant-${variant.status}`, 'variant-quantity']"
                />
                <IconLibrary
                  name="hide"
                  color="red"
                  size="xs"
                  class="variant-hide"
                  :hasMargin="false"
                  v-if="variants.length > 1 && variant.status !== 'deleted' && !isProductOwner"
                  @click="hideVariant(variant._id)"
                />
                <IconLibrary
                  name="show"
                  color="green"
                  size="xs"
                  class="variant-show"
                  :hasMargin="false"
                  v-if="variant.status === 'deleted' && !isProductOwner"
                  @click="showVariant(variant._id)"
                />
                <FormCheckbox
                  :class="[`variant-${variant.status}`, 'variant-backorder']"
                  v-model="variant.allow_backorder"
                  label="forms.label.allow-backorder"
                  :disabled="!isProductOwner"
                />
              </li>
            </ul>
          </div>
        </aside>
      </form>
      <div v-if="errorMessage" class="error-message">
        {{ $t(errorMessage) }}
      </div>
      <footer>
        <div class="actions">
          <router-link to="/products" class="btn btn-ghost btn-lg">
            <IconLibrary name="back" size="sm" />
            {{ $t('ui.back') }}
          </router-link>
          <div class="btn btn-lg" @click="onSubmit">
            <IconLibrary name="download" color="white" size="sm" />
            {{ $t('ui.submit') }}
          </div>
        </div>
      </footer>
    </main>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { kyWithAuth } from '../libs/ky';
import { useUserStore } from '../stores/user';
import { useCategoryStore } from '../stores/category';
import IconLibrary from '../components/Shared/IconLibrary.vue';
import FormInput from '../components/Shared/Forms/FormInput.vue';
import FormNumberInput from '../components/Shared/Forms/FormNumberInput.vue';
import FormPriceInput from '../components/Shared/Forms/FormPriceInput.vue';
import FormTextArea from '../components/Shared/Forms/FormTextArea.vue';
import FormUpload from '../components/Shared/Forms/FormUpload.vue';
import FormCheckbox from '../components/Shared/Forms/FormCheckbox.vue';
import ProductOptions from '../components/Products/ProductOptions.vue';

const route = useRoute();
const router = useRouter();
const userStore = useUserStore();
const categoryStore = useCategoryStore();

const userRole = userStore.user.role;
const productId = route.params.product_id;

const isProductOwner = ref(true);
const errorMessage = ref('');
const initialForm = ref({});
const initialCategory = ref();

const form = ref({
  name: '',
  description: '',
  status: '',
  category: '',
  tags: '',
  vendor: '',
  images: [],
  options: [],
  variants: [],
  quantity_rules: { increment: '', min: '', max: '' },
  seller_price: null
});

onMounted(async () => {
  if (productId) {
    const { product } = await kyWithAuth.get(`products/${productId}`).json();
    isProductOwner.value =
      product.seller_id === userStore.user._id || product.director_id === userStore.user._id;
    initialCategory.value = product.category;
    form.value = {
      name: product.name,
      description: product.description,
      status: product.status,
      category: product.category,
      tags: product.tags,
      vendor: product.vendor,
      images: product.images,
      options: product.options,
      variants: product.variants,
      quantity_rules: {
        increment: product.quantity_rules?.increment,
        min: product.quantity_rules?.min,
        max: product.quantity_rules?.max
      }
    };
    //INFO: Ensure deep copy of object
    initialForm.value = JSON.parse(JSON.stringify(form.value));
  }
});

const cartesian = (...arrays) => {
  if (arrays.some((array) => array.length === 0)) {
    return [[]];
  }

  return arrays.reduce(
    (accumulator, currentArray) =>
      accumulator.flatMap((accumulatorValue) =>
        currentArray.map((currentValue) => [...accumulatorValue, currentValue])
      ),
    [[]]
  );
};

function getBlankVariants() {
  const optionNames = form.value.options.map((option) => option.name);
  const variantOptions = optionNames.map((name) => ({
    name,
    values: form.value.options.find((option) => option.name === name)?.values || []
  }));

  const variantValues = cartesian(...variantOptions.map((option) => option.values));

  return variantValues.map((values) => {
    const variant = variantOptions.reduce((variant, option, index) => {
      variant.options = [{ value: values[index], name: option.name }];
      return variant;
    }, {});

    variant.title = values.filter(Boolean).join(' / ');
    variant.price = null;
    variant.purchase_price = null;
    variant.sku = '';
    variant.quantity = null;
    variant.status = 'active';
    variant.allow_backorder = false;

    return variant;
  });
}

const variants = computed(() => {
  if (form.value.variants.length < 1) return getBlankVariants();
  return form.value.variants;
});

const hideVariant = (variantId) => {
  const variantToHide = form.value.variants.find((variant) => variant._id === variantId);
  if (variantToHide) {
    variantToHide.status = 'deleted';
  }
};

const showVariant = (variantId) => {
  const variantToShow = form.value.variants.find((variant) => variant._id === variantId);
  if (variantToShow) {
    variantToShow.status = 'active';
  }
};

function getChangedParams(newObj = form.value, oldObj = initialForm.value, prefix = '') {
  const changedParams = [];

  for (const key in newObj) {
    const newKey = prefix ? `${prefix}.${key}` : key;

    if (Array.isArray(newObj[key])) {
      // Handle arrays
      newObj[key].forEach((item, index) => {
        if (typeof item === 'object' && item !== null) {
          const idKey = item._id || index;
          changedParams.push(
            ...getChangedParams(item, oldObj[key] ? oldObj[key][index] : {}, `${newKey}.${idKey}`)
          );
        } else {
          // Compare arrays of primitive values
          if (JSON.stringify(newObj[key]) !== JSON.stringify(oldObj[key])) {
            changedParams.push(newKey);
          }
        }
      });
    } else if (typeof newObj[key] === 'object' && newObj[key] !== null) {
      // Recurse into nested object
      changedParams.push(...getChangedParams(newObj[key], oldObj[key] || {}, newKey));
    } else {
      // Compare primitive values
      if (newObj[key] !== oldObj[key]) {
        changedParams.push(newKey);
      }
    }
  }

  return changedParams;
}

function hasPriceChanged(changedParams) {
  return changedParams.some((param) => param.includes('price'));
}

function hasCategoryChanged(changedParams) {
  return changedParams.some((param) => param.includes('category'));
}

async function onSubmit() {
  errorMessage.value = '';
  const existingImages = form.value.images.filter((image) => typeof image === 'string');
  const newFiles = form.value.images.filter((image) => typeof image !== 'string');

  if (existingImages.length === 0 && newFiles.length === 0) {
    errorMessage.value = 'products.error.no_images';
    return;
  }

  try {
    let uploadedImageURLs = [];
    if (newFiles.length) {
      const formData = new FormData();
      for (const file of newFiles) {
        formData.append('images', file);
      }
      try {
        uploadedImageURLs =
          (await kyWithAuth.post('products/product-images', { body: formData }).json()) || [];
      } catch (error) {
        errorMessage.value = 'products.error.image_upload_failed';
        return;
      }
    }
    const json = {
      ...form.value,
      status: 'active',
      images: [...existingImages, ...uploadedImageURLs],
      variants: variants.value || form.value.variants
    };

    if (userStore.user.role === 'director' && form.value.category) {
      const categoryExists = await categoryStore.fetchCategory(form.value.category);
      if (!categoryExists) await kyWithAuth.post(`categories/${form.value.category}`).json();
    }

    if (productId) {
      const modifiedParams = getChangedParams();
      const data = hasPriceChanged(modifiedParams)
        ? {
            ...json,
            modified_params: modifiedParams
          }
        : json;
      await kyWithAuth.patch(`products/${productId}`, { json: data }).json();

      if (userStore.user.role === 'director' && hasCategoryChanged(modifiedParams)) {
        const { products: productsWithCategory } = await kyWithAuth
          .get(`products/category/${initialCategory.value}`)
          .json();
        if (!productsWithCategory.length)
          await kyWithAuth.delete(`categories/${initialCategory.value}`).json();
      }
    } else await kyWithAuth.post('products', { json }).json();
    router.push({ name: 'Products' });
  } catch (error) {
    errorMessage.value = 'products.error.general';
    console.error(error);
  }
}
</script>

<style scoped lang="scss">
#page main > header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .actions {
    display: flex;
    gap: 20px;
  }
}

#page main > footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 25px;
  .actions {
    display: flex;
    gap: 20px;
  }
}

h6 {
  margin-bottom: 12px;
  font-size: 95%;
}
form main {
  display: grid;
  grid-template-columns: 1fr minmax(300px, 35%);
  gap: 25px;
}
form aside {
  margin-top: 25px;
}
.variants {
  margin: 0;
  padding: 0;
}
.variant- {
  &title {
    grid-area: title;
  }
  &sku {
    grid-area: sku;
  }
  &price {
    grid-area: price;
  }
  &purchase-price {
    grid-area: purchase-price;
  }
  &quantity {
    grid-area: quantity;
  }
  &show {
    grid-area: action;
  }
  &hide {
    grid-area: action;
  }
  &backorder {
    grid-area: backorder;
  }
}
.variant {
  display: grid;
  grid-template-columns: 1.2fr 0.8fr 130px 130px 130px 32px;
  grid-template-areas:
    'title sku price purchase-price quantity action'
    'backorder backorder backorder backorder backorder backorder';
  align-items: center;
  gap: 8px 20px;
  .icon-wrapper {
    flex-shrink: 0;
    margin-top: 24px;
  }
  .form-input,
  .form-checkbox {
    padding: 0 0;
    label {
      color: rgba($black, 0.65);
    }
  }
}
.variant + .variant {
  margin-top: 25px;
}
.variant-deleted {
  opacity: 0.3;
}
ul.options {
  padding: 20px;
  margin: 0 0 20px;
  list-style: none;
  background-color: $brand-light-grey;
  border: 1px solid $brand-light-purple;
  border-radius: $base-radius;
  .option-name {
    margin-bottom: 8px;
    font-weight: $font-weight-bold;
  }
  .flex {
    justify-content: flex-start;
    gap: 12px;
  }
  .tag {
    background-color: $brand-light-purple;
    color: $white;
    padding: 3px 15px;
    border-radius: $base-radius;
  }
}

.error-message {
  background-color: $white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 2px 2px 6px rgba($black, 0.05);
  margin: 20px 0;
  border: 2px solid $brand-dark-corail;
  color: darken($brand-dark-corail, 20%);
}

.boxed + .boxed {
  margin-top: 20px;
}

@media screen and (max-width: 1040px) {
  form main {
    display: grid;
    grid-template-columns: 1fr;
    gap: 25px;
  }
}
</style>
