<template>
  <header>
    <router-link class="logo" to="/">
      <img class="logo-small" src="/logos/logo-nuteam-white-small.svg" alt="NuTeam Logo" />
    </router-link>
    <nav class="notifications">
      <router-link class="orders" to="/orders">
        <IconLibrary name="order" size="sm" />
        <span class="tag count">{{ orderStore.orderCount }}</span>
      </router-link>
    </nav>
    <nav class="profile" v-if="userStore.user.is_kyc">
      <div v-if="userStore.user?.full_name" class="round" :style="{ backgroundColor: randomColor }">
        {{ initials }}
      </div>
      <div>
        <span>{{ userStore.user?.full_name }}</span>
        <br />
        <span class="blue">
          <small>
            <strong class="capitalize">{{ userStore.user?.role }}</strong>
          </small>
        </span>
      </div>
    </nav>
  </header>
</template>

<script setup>
import { computed } from 'vue';
import IconLibrary from './IconLibrary.vue';
import { useUserStore } from '../../stores/user';
import { useOrderStore } from '../../stores/orders.js';

const userStore = useUserStore();
const orderStore = useOrderStore();

const initials = computed(() => {
  if (!userStore.user.full_name) return '';
  return userStore.user.full_name
    .split(' ')
    .filter((name) => name.trim().length > 0)
    .map((name) => name.charAt(0))
    .join('');
});

const randomColor = computed(() => {
  const colors = ['#FF5733', '#33FF57', '#5733FF', '#33A1FD', '#FD33A1'];
  return colors[Math.floor(Math.random() * colors.length)];
});
</script>

<style lang="scss" scoped>
header {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  align-items: center;
  @include responsive-padding(sm, md, lg);
  .logo {
    display: none;
  }
}
nav.notifications {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  gap: 12px;
  > div {
    cursor: pointer;
    transition: transform 150ms ease-in-out;
    &:hover {
      transform: scale(1.1);
    }
  }
  .tag.count {
    bottom: inherit;
    left: inherit;
  }
}
nav.profile {
  height: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 0.85rem;
  .round {
    width: 40px;
    height: 40px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: $font-weight-bold;
    font-size: 1rem;
    color: $white;
  }
}

@media (max-width: 767px) {
  header {
    justify-content: flex-end;
    .logo {
    }
    .logo {
      display: block;
      width: 40px;
      height: 40px;
      background-color: $brand-dark-purple;
      border-radius: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      @include margin(auto, right);
      img {
        width: 65%;
      }
    }
    nav.notifications .orders {
      display: none;
    }
  }
}
</style>
