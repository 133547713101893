<template>
  <div class="catalogs">
    <article v-for="catalog in catalogs" class="catalog-wrapper">
      <router-link class="catalog" :to="`/catalogs/${catalog._id}`">
        <div class="name">
          <strong>{{ catalog.name }}</strong>
        </div>
        <div class="status">
          <strong>{{ catalog.status || '' }}</strong>
        </div>
        <div class="products">
          <strong>{{ catalog.products?.length || 0 }}</strong> produits
        </div>
        <div class="directors">
          <strong>{{ catalog.directors?.length || 0 }}</strong> clients
        </div>
      </router-link>
      <div class="btn btn-sm btn-ghost btn-delete" @click="deleteCatalog(catalog._id)">
        <IconLibrary name="delete" color="red" size="xs" />
        Delete
      </div>
    </article>
  </div>
</template>

<script setup>
import IconLibrary from '../Shared/IconLibrary.vue';
import { useCatalogStore } from '../../stores/catalogs.js';

const catalogStore = useCatalogStore();

const { catalogs } = defineProps({
  catalogs: {
    type: Array,
    required: true
  }
});

const deleteCatalog = async (catalogId) => {
  try {
    catalogStore.deleteCatalog(catalogId);
  } catch (e) {
    console.error('Error deleting catalog:', e);
  }
};
</script>

<style lang="scss" scoped>
.catalogs {
  margin-top: 20px;
}
nav.filters {
  display: flex;
  align-items: center;
  gap: 16px;
  border-bottom: 1px solid $gainsboro;
  a {
    text-decoration: none;
    font-size: 90%;
    color: #444444;
    padding: 12px 8px;
    margin-bottom: -1px;
    border-bottom: 3px solid transparent;
  }
  a.active {
    border-bottom: 3px solid $brand-blue;
  }
  a:hover {
    border-bottom: 3px solid $gainsboro;
  }
}
section.search {
  display: flex;
  gap: 20px;
  margin: 20px 0;
}

.catalog-wrapper {
  display: grid;
  grid-template-columns: 1fr 100px;
  gap: 0px 20px;
  width: 100%;
  padding: 10px 15px;
  background-color: $white;
  transition: background-color $base-speed-fast ease;
}
.catalog {
  display: grid;
  grid-template-columns: 1fr 150px 150px auto;
  grid-template-areas: 'name status products directors';
  align-items: center;
  gap: 0px 15px;
  width: 100%;
  color: $brand-deep-purple;
  padding: 10px 15px 10px 0px;
  text-decoration: none;
  font-weight: $font-weight-light;
  transition: background-color $base-speed-fast ease;
}

article.catalog-wrapper:nth-of-type(even) {
  border-top: 1px dashed rgba($brand-light-purple, 0.75);
  border-bottom: 1px dashed rgba($brand-light-purple, 0.75);
}
article.catalog-wrapper:last-child {
  border-bottom: none;
}
article.catalog-wrapper:hover {
  cursor: pointer;
  background-color: rgba($brand-mint, 0.05);
}
article.catalog-wrapper.router-link-active {
  background-color: rgba($brand-light-purple, 0.4);
  color: $white;
}

article.catalog:nth-of-type(even) {
  border-top: 1px dashed rgba($brand-light-purple, 0.75);
  border-bottom: 1px dashed rgba($brand-light-purple, 0.75);
}
article.catalog:last-child {
  border-bottom: none;
}
article.catalog:hover {
  cursor: default;
  background-color: rgba($brand-mint, 0.05);
}
.green {
  color: green;
}
.added-to-shop {
  display: flex;
  border: 1px solid rgba(green, 0.25);
  padding: 4px 12px;
  border-radius: $base-radius;
}
// Grid Area

.status {
  grid-area: status;
}
.directors {
  grid-area: directors;
  justify-self: flex-end;
}
.name {
  grid-area: name;
}
.products {
  grid-area: products;
}
</style>
