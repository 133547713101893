<template>
  <div id="page">
    <main>
      <header>
        <h2>{{ $t('products.title') }}</h2>
        <div class="actions">
          <router-link to="/products/new" class="btn btn-lg">
            <IconLibrary name="add-product" color="white" size="sm" />
            Créer un produit
          </router-link>
          <button class="btn btn-lg" @click="openModal">
            <IconLibrary name="upload" color="white" size="sm" />
            Importer
          </button>
        </div>
      </header>

      <ProductSearch class="products-search" />
      <div v-if="productStore.products?.length < 1" class="boxed products">
        <EmptyState>
          <IconLibrary name="product" size="xxl" />
          <h4>No Products</h4>
          <p>
            Uh-oh, looks like you haven't any products yet!<br />
            To get started, click on the following link
            <router-link to="/products/new">to create your first product</router-link>.
          </p>
          <p>
            <small>
              Once created, it will appear here and you can use your products right away.
            </small>
          </p>
        </EmptyState>
      </div>
      <div v-else>
        <ProductList class="boxed" />
        <Pagination
          :currentPage="productStore.currentPage"
          :hasNextPage="productStore.hasNextPage"
          :pageSize="productStore.pageSize"
          @update:page="changePage"
        />
      </div>
    </main>
  </div>
  <Modal ref="modal">
    <template #title>Importez vos produits via un fichier CSV.</template>
    <div class="import-container">
      <h6>Instructions</h6>
      <div class="import-notes">
        <ul>
          <li><a href="/templates/products.csv" download>Télécharger le modèle CSV</a></li>
          <li>Une ligne = une variante de produit</li>
          <li>Utilisez des guillemets (") pour les champs contenant des virgules</li>
          <li>Les tags doivent être séparés par des virgules</li>
          <li>Le champ "Allow Backorder" accepte uniquement "true" ou "false"</li>
        </ul>
      </div>

      <FormUpload
        v-model="selectedFiles"
        :allow-multiple="false"
        :max-files="1"
        accept=".csv"
        @update:modelValue="handleFileUpdate"
      />

      <div v-if="uploadStatus" class="upload-status">
        {{ uploadStatus }}
      </div>
    </div>
    <template #footer>
      <div class="btn btn-ghost" @click="handleCancel">Cancel</div>
      <button class="btn" @click="uploadFile" :disabled="!selectedFile">Importer</button>
    </template>
  </Modal>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import ProductList from '../components/Products/ProductList.vue';
import Pagination from '../components/Shared/Pagination.vue';
import IconLibrary from '../components/Shared/IconLibrary.vue';
import EmptyState from '../components/Shared/EmptyState.vue';
import { useProductStore } from '../stores/products.js';
import ProductSearch from '../components/Products/ProductSearch.vue';
import Modal from '../components/Shared/Modal.vue';
import FormUpload from '../components/Shared/Forms/FormUpload.vue';

const productStore = useProductStore();
const modal = ref();
const selectedFiles = ref([]);
const selectedFile = ref(null);
const uploadStatus = ref('');

const openModal = () => {
  modal.value.open();
};

const closeModal = () => {
  modal.value.close();
  resetForm();
};

const resetForm = () => {
  selectedFile.value = null;
  selectedFiles.value = [];
  uploadStatus.value = '';
};

const handleCancel = () => closeModal();

function changePage(page) {
  productStore.currentPage = page;
  productStore.fetchProducts({ page, pageSize: productStore.pageSize });
}

onMounted(async () => {
  try {
    await productStore.fetchProducts();
  } catch (e) {
    console.error(e);
  }
});

const handleFileUpdate = (files) => {
  selectedFiles.value = files;
  selectedFile.value = files[0] || null;
  uploadStatus.value = selectedFile.value ? '' : 'Please select a valid CSV file';
};

const uploadFile = async () => {
  if (!selectedFile.value) return;

  const formData = new FormData();
  formData.append('csv', selectedFile.value);

  try {
    uploadStatus.value = 'Uploading...';
    await productStore.importProducts(formData);
    uploadStatus.value = 'Import successful!';
    setTimeout(closeModal, 1500);
    await productStore.fetchProducts();
  } catch (error) {
    uploadStatus.value = `Error: ${error.message || 'Upload failed'}`;
  }
};
</script>

<style scoped lang="scss">
#page main > header:not(.products-search) {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .actions {
    display: flex;
    gap: 20px;
  }
}

.import-container {
  padding: 20px;
}

.import-info {
  margin-bottom: 20px;
}

.upload-status {
  margin: 10px 0;
  color: var(--color-text-secondary);
}

.modal-actions {
  margin-top: 20px;
  text-align: right;
}

.download-links {
  margin-top: 10px;
}

.separator {
  margin: 0 8px;
  color: var(--color-text-secondary);
}

.import-notes {
  margin: 15px 0;
  padding: 12px 15px;
  background-color: var(--color-background-alt);
  border-radius: 6px;
}

.import-notes ul {
  margin: 0;
  padding: 0;
  color: var(--color-text-secondary);
}

.import-notes li {
  margin: 4px 0;
}
</style>
