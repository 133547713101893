<template>
  <div id="page">
    <main>
      <header>
        <h2>{{ $t('buyers.edit.title') }}</h2>
        <div class="actions">
          <router-link to="/pos" class="btn btn-lg">
            <IconLibrary name="back" color="white" size="sm" />
            {{ $t('ui.back') }}
          </router-link>
          <div class="btn btn-lg" @click="submitForm">
            <IconLibrary name="download" color="white" size="sm" />
            {{ $t('ui.submit') }}
          </div>
        </div>
      </header>
      <div class="settings">
        <form @submit.prevent="submitForm" id="edit-sale-point">
          <ul>
            <li class="contact">
              <aside>
                <h6>{{ $t('buyer.contact.title') }}</h6>
                <p>
                  <small>{{ $t('buyer.contact.description') }}</small>
                </p>
              </aside>
              <div class="boxed">
                <section class="flex">
                  <FormInput title="forms.label.company" v-model="form.company" />
                  <FormInput title="forms.label.full_name" v-model="form.full_name" />
                </section>
                <section class="flex">
                  <FormInput title="forms.label.phone" v-model="form.phone" />
                  <FormInput title="forms.label.email" v-model="form.email" :disabled="true" />
                </section>
              </div>
            </li>
            <li>
              <aside>
                <h6>{{ $t('buyer.address.title') }}</h6>
                <p>
                  <small>{{ $t('buyer.address.description') }}</small>
                </p>
              </aside>
              <AddressEdit class="boxed" v-model="form.address" />
            </li>
            <li v-if="!isShopProfile">
              <aside>
                <h6>{{ $t('buyer.settings.title') }}</h6>
              </aside>
              <div class="boxed">
                <FormCheckbox
                  id="auto_validate"
                  label="buyer.settings.auto_validate"
                  v-model="options.auto_validate"
                />
              </div>
            </li>
          </ul>
        </form>
      </div>
      <footer v-if="!verified">
        <div class="btn btn-ghost btn-cancel btn-lg" @click="deleteBuyer()">
          <IconLibrary name="delete" color="red" size="xs" />Delete
        </div>
      </footer>
    </main>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { kyWithAuth } from '../libs/ky';
import IconLibrary from '../components/Shared/IconLibrary.vue';
import AddressEdit from '../components/Shared/Address/AddressEdit.vue';
import FormInput from '../components/Shared/Forms/FormInput.vue';
import FormCheckbox from '../components/Shared/Forms/FormCheckbox.vue';
import { useUserStore } from '../stores/user';

const route = useRoute();
const router = useRouter();
const userStore = useUserStore();

const buyerId = route.params.pos_id;
const isShopProfile = computed(() => route.path.startsWith('/shop/profile'));

const form = ref({
  identifier: '',
  full_name: '',
  company: '',
  phone: '',
  email: '',
  address: {
    country: 'FR',
    address1: '',
    address2: '',
    zip: '',
    city: ''
  }
});

const options = ref({
  auto_validate: false
});

const verified = ref(false);

onMounted(async () => {
  const { user: buyer } = await kyWithAuth.get(`users/${buyerId}`).json();
  verified.value = buyer.is_verified;
  form.value = {
    full_name: buyer.full_name,
    company: buyer.company,
    phone: buyer.phone,
    email: buyer.email,
    address: buyer.address
  };

  if (!isShopProfile.value) {
    options.value = {
      auto_validate: userStore.user.buyers.find(
        (buyer) => buyer?.user_id?.toString() === buyerId?.toString()
      )?.auto_validate
    };
  }
});

async function deleteBuyer() {
  try {
    await kyWithAuth.delete(`users/${buyerId}`);
    router.push({ name: 'Buyers' });
  } catch (e) {
    console.error(e);
  } finally {
    //Loading false
  }
}

async function submitForm() {
  try {
    await kyWithAuth.patch(`users/${buyerId}`, { json: form.value }).json();
    if (!isShopProfile.value) {
      await kyWithAuth.patch(`users/buyers/${buyerId}/options`, { json: options.value }).json();
    }
    router.push({ name: isShopProfile.value ? 'Shop' : 'Buyers' });
  } catch (e) {
    console.error(e);
  } finally {
    //Loading false
  }
}
</script>

<style scoped lang="scss">
:deep(label) {
  display: block;
  color: $brand-light-purple;
  font-size: 85%;
  margin-bottom: 5px;
}
.flex {
  gap: 25px;
}

#page main > header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .actions {
    display: flex;
    gap: 20px;
  }
}
.profile {
  height: 100%;
  display: flex;
  align-items: center;
  gap: 15px;
  img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 100px;
  }
}
.settings ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.settings ul > li {
  display: flex;
  justify-content: space-between;
  gap: 50px;
  > div {
    min-width: 65%;
    padding: 15px 30px;
  }
  > aside {
    p {
      color: $brand-light-purple;
      font-style: italic;
    }
  }
}
.settings ul > li + li {
  margin-top: 35px;
}

footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}
@media (max-width: 767px) {
  .settings ul > li {
    flex-direction: column;
    gap: 10px;
  }
  #page main > header {
    flex-direction: column;
  }
}
</style>
