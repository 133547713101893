export { useProductStore };
import { defineStore } from 'pinia';
import { kyWithAuth } from '../libs/ky';
import { queryStringify } from '../libs/utils.js';

const useProductStore = defineStore('products', {
  state: () => {
    return {
      products: [],
      collections: [],
      catalog: [],
      product: {},
      variant: {},
      options: {},
      currentPage: 1,
      pageSize: 0,
      hasNextPage: false,
      searchQuery: null,
      error: null,
      loading: false
    };
  },
  getters: {
    getSortedProducts: (state) => {
      return state.products;
    }
  },
  actions: {
    setSearchQuery(query) {
      this.searchQuery = query;
      this.currentPage = 1;
      this.fetchProducts();
    },
    setPage(page) {
      this.currentPage = page;
      this.fetchProducts({ page, pageSize: this.pageSize });
    },
    async fetchProducts({
      page = 1,
      pageSize = 30,
      catalog = false,
      conditions = undefined,
      products = undefined,
      category = undefined,
      priceRange = undefined,
      seller = undefined,
      shop = false
    } = {}) {
      this.loading = true;
      this.error = null;
      const searchParams = queryStringify({
        page,
        limit: pageSize,
        shop,
        ...(this.searchQuery && { search: this.searchQuery }),
        ...(products && { products }),
        ...(category && { category }),
        ...(conditions && { conditions }),
        ...(priceRange && priceRange.min !== undefined && { 'priceRange.min': priceRange.min }),
        ...(priceRange && priceRange.max !== undefined && { 'priceRange.max': priceRange.max }),
        ...(seller && { seller })
      });
      try {
        const endpoint = catalog ? 'products/catalog' : 'products';
        const response = await kyWithAuth.get(endpoint, { searchParams }).json();
        const getMinMaxPrices = (product) => {
          const prices = product.variants.map((variant) => variant.price);
          const minPrice = Math.min(...prices);
          const maxPrice = Math.max(...prices);
          return { minPrice, maxPrice };
        };
        if (catalog) {
          const { shop = {} } = await kyWithAuth.get('shops').json();
          const productsWithDetails = response.products.map((product) => {
            const { minPrice, maxPrice } = getMinMaxPrices(product);
            const shopProducts = shop.products || [];
            const isInShop = shopProducts.some(
              (shopProduct) => shopProduct.product_id === product._id
            );
            return {
              ...product,
              included: !!isInShop,
              minPrice,
              maxPrice
            };
          });
          this.catalog = productsWithDetails;
        } else {
          this.products = response.products;
        }
        this.currentPage = response.currentPage;
        this.hasNextPage = response.hasNextPage;
        this.pageSize = pageSize;
        if (process.env.NODE_ENV === 'development') {
          console.log(catalog ? 'catalog:' : 'products:', response.products);
        }
      } catch (e) {
        console.error('Error fetching products:', e);
        this.error = 'Failed to fetch products. Please try again later.';
        // this.catalog = catalog ? [] : this.catalog;
        // this.products = catalog ? this.products : [];
      } finally {
        this.loading = false;
      }
    },
    async fetchCollectionProducts({ type, payload, page = 1, pageSize = 25 }) {
      this.loading = true;
      try {
        let queryParams = { page, pageSize };

        if (type === 'manual') {
          queryParams.products = payload;
        } else if (type === 'automated') {
          queryParams.conditions = payload.map(
            (condition) => `${condition.field}__${condition.condition}:${condition.value}`
          );
        }
        await this.fetchProducts(queryParams);
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    async fetchProduct(productId) {
      this.loading = true;

      try {
        const { product = {} } = await kyWithAuth.get(`products/${productId}`).json();
        this.product = product;
        this.variant = product.variants.find((variant) => variant.status !== 'deleted');
      } catch (error) {
        console.error('Error fetching product:', error);
      }

      this.loading = false;
    },
    async fetchProductByCategory(category) {
      try {
        const res = await kyWithAuth.get(`products/category/${category}`).json();
        return res.products;
      } catch (error) {
        console.error('Error fetching product:', error);
      }
    },
    async fetchShop() {
      this.loading = true;

      try {
        const { shop = {} } = await kyWithAuth.get('shops').json();
        this.shop = shop?.products || [];
      } catch (error) {
        console.error('Error fetching shop:', error);
      }

      this.loading = false;
    },
    async clearProduct() {
      this.product = {};
      this.variant = {};
      this.options = {};
    },
    async deleteProduct(productId) {
      try {
        await kyWithAuth.delete(`products/${productId}`);
        this.products = this.products.filter((product) => product._id !== productId);
      } catch (e) {
        console.error(e);
      }
    },
    async addToShop(productId) {
      try {
        const foundProduct = this.catalog.find((product) => product._id === productId);
        if (foundProduct) {
          await kyWithAuth.patch(`shops/products/add/${productId}`, {
            json: { catalog_id: foundProduct.catalog_id }
          });
          foundProduct.included = true;
          if (foundProduct.category) {
            const foundCategory = await kyWithAuth
              .get(`categories/${foundProduct.category}`)
              .json();
            if (!foundCategory) await kyWithAuth.post(`categories/${foundProduct.category}`).json();
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    async removeFromShop(productId) {
      try {
        const foundProduct = this.catalog.find((product) => product._id === productId);
        if (foundProduct) {
          await kyWithAuth.patch(`shops/products/remove/${productId}`, {
            json: { catalog_id: foundProduct.catalog_id }
          });
          foundProduct.included = false;
          const { products: productsWithCategory } = await kyWithAuth
            .get(`products/category/${foundProduct.category}`)
            .json();
          if (!productsWithCategory.length)
            await kyWithAuth.delete(`categories/${foundProduct.category}`).json();
        }
      } catch (error) {
        console.error(error);
      }
    },
    async importProducts(formData) {
      this.loading = true;
      this.error = null;

      try {
        const response = await kyWithAuth
          .post('products/import', {
            body: formData
          })
          .json();

        if (process.env.NODE_ENV === 'development') {
          console.log('Import response:', response);
        }

        return response;
      } catch (error) {
        console.error('Error importing products:', error);
        this.error = 'Failed to import products. Please try again later.';
        throw error;
      } finally {
        this.loading = false;
      }
    }
  }
});
