<template>
  <div id="page">
    <main>
      <img class="illustration" src="/svg/illustration_home.svg" />
      <h2>
        Bonjour<br /><strong class="blue">{{ userStore.user?.full_name }}</strong
        >,
      </h2>
      <p>Voici vos notifications du jour</p>
      <nav class="notifications boxed">
        <router-link to="/orders">
          <span>
            <IconLibrary name="order" color="blue" size="sm" />
            <div>
              <strong>{{ orderStore.orderCount }}</strong> commandes en attente de validation
            </div>
          </span>
          <IconLibrary name="arrow" color="blue" size="sm" class="arrow" />
        </router-link>
        <!-- <router-link to="/messages">
          <span>
            <IconLibrary name="message" color="blue" size="sm" />
            <div><strong>0</strong> messages non lu</div>
          </span>
          <IconLibrary name="arrow" color="blue" size="sm" class="arrow" />
        </router-link> -->
      </nav>
      <section class="notification-center boxed" v-if="userStore?.user?.role === 'director'">
        <header>
          <IconLibrary name="product" color="blue" size="sm" />
          <div>
            <strong>{{ notifications.length ? notifications.length : 'Aucune' }}</strong>
            notification(s) de modification de produits
          </div>
        </header>
        <div v-if="notifications.length">
          <div
            v-for="notification in notifications"
            :key="notification._id"
            class="notification"
            :class="{ 'is-read': notification.read }"
          >
            <div class="message">
              <img
                :src="notification.product_image"
                class="round"
                v-if="notification.product_image"
              />
              <div>
                Votre fournisseur <strong>{{ notification.seller_name }}</strong> a modifié
                <strong>le prix</strong> du produit
                <strong>{{ notification.product_name }}</strong>
                <br />Ce produit a été retiré de votre boutique afin que vous puissiez vérifier et
                valider les modifications.
              </div>
            </div>
            <button
              class="btn btn-sm"
              @click="markAsRead(notification._id, notification.product_id)"
              v-if="!notification.read"
            >
              Voir produit
            </button>
          </div>
        </div>
      </section>
      <section class="product-ranking boxed">
        <header class="flex">
          <p><strong>Produits les plus vendus</strong></p>
          <div>
            <select v-model="productPeriod" @change="fetchRankedProducts">
              <option value="current_month">Mois en cours</option>
              <option value="last_month">Dernier mois</option>
              <option value="last_3_months">3 derniers mois</option>
              <option value="last_6_months">6 derniers mois</option>
              <option value="last_year">Dernière année</option>
            </select>
            <button class="btn btn-sm" @click="exportCSV('products')">Export CSV</button>
          </div>
        </header>
        <div class="ranking-header">
          <span></span>
          <span>Nom - Variante</span>
          <span>Quantité</span>
          <span></span>
        </div>
        <div class="product-list">
          <router-link
            class="product"
            v-for="product in rankedProducts"
            :to="getProductRoute(product.product_id)"
            :key="product.variant_id"
          >
            <img
              class="round"
              :src="product.image"
              :alt="product.name"
              loading="lazy"
              decoding="async"
            />
            <p>
              {{ product.name }} <strong>{{ product.options }}</strong>
            </p>
            <p>{{ product.quantity }}</p>
            <IconLibrary name="arrow" color="blue" size="sm" class="arrow" />
          </router-link>
        </div>
      </section>
      <section class="buyer-ranking boxed">
        <header class="flex">
          <p><strong>Ventes par acheteurs</strong></p>
          <div>
            <select v-model="buyerPeriod" @change="fetchTopBuyers">
              <option value="current_month">Mois en cours</option>
              <option value="last_month">Dernier mois</option>
              <option value="last_3_months">3 derniers mois</option>
              <option value="last_6_months">6 derniers mois</option>
              <option value="last_year">Dernière année</option>
            </select>
            <button class="btn btn-sm" @click="exportCSV('buyers')">Export CSV</button>
          </div>
        </header>
        <div class="ranking-header">
          <span>Acheteur</span>
          <span>Commandes</span>
          <span>Dernière commande</span>
          <span>Total</span>
        </div>
        <div class="buyer" v-for="(buyer, index) in buyersWithPercentage" :key="buyer._id">
          <div class="fill-bar" :style="{ width: `${buyer.percentage}%` }"></div>
          <span>{{ buyer.buyerName }}</span>
          <span>{{ buyer.orderCount }}</span>
          <span>{{ formatDate(buyer.lastOrderDate) }}</span>
          <span>
            <MoneyFormat :amount="buyer.totalAmount" currency="EUR" />
          </span>
        </div>
      </section>
      <section class="seller-ranking boxed" v-if="userStore?.user?.role === 'director'">
        <header class="flex">
          <p><strong>Ventes par vendeurs</strong></p>
          <div>
            <select v-model="sellerPeriod" @change="fetchSellerRanking">
              <option value="current_month">Mois en cours</option>
              <option value="last_month">Dernier mois</option>
              <option value="last_3_months">3 derniers mois</option>
              <option value="last_6_months">6 derniers mois</option>
              <option value="last_12_months">12 derniers mois</option>
            </select>
            <button class="btn btn-sm" @click="exportCSV('sellers')">Export CSV</button>
          </div>
        </header>
        <div class="ranking-header">
          <span>Vendeur</span>
          <span>Commandes</span>
          <span>Montant total</span>
        </div>
        <div class="seller" v-for="(seller, index) in sellersWithPercentage" :key="seller._id">
          <div class="fill-bar" :style="{ width: `${seller.percentage}%` }"></div>
          <span>{{ seller.sellerName }}</span>
          <span>{{ seller.orderCount }}</span>
          <span>
            <MoneyFormat :amount="seller.totalAmount" currency="EUR" />
          </span>
        </div>
      </section>
      <section class="director-ranking boxed" v-if="userStore?.user?.role === 'seller'">
        <header class="flex">
          <p><strong>Ventes par directeurs</strong></p>
          <div>
            <select v-model="directorPeriod" @change="fetchDirectorRanking">
              <option value="current_month">Mois en cours</option>
              <option value="last_month">Dernier mois</option>
              <option value="last_3_months">3 derniers mois</option>
              <option value="last_6_months">6 derniers mois</option>
              <option value="last_12_months">12 derniers mois</option>
            </select>
            <button class="btn btn-sm" @click="exportCSV('directors')">Export CSV</button>
          </div>
        </header>
        <div class="ranking-header">
          <span>Directeur</span>
          <span>Commandes</span>
          <span>Montant total</span>
        </div>
        <div
          class="director"
          v-for="(director, index) in directorsWithPercentage"
          :key="director._id"
        >
          <div class="fill-bar" :style="{ width: `${director.percentage}%` }"></div>
          <span>{{ director.directorName }}</span>
          <span>{{ director.orderCount }}</span>
          <span>
            <MoneyFormat :amount="director.totalAmount" currency="EUR" />
          </span>
        </div>
      </section>
    </main>
    <aside>
      <section>
        <header class="flex">
          <span><small>TOTAL DES ACHATS</small></span>
          <span style="opacity: 0.6">
            <small>{{ new Date().getFullYear() }}</small>
          </span>
        </header>
        <span style="margin-top: 4px; display: block">
          <strong>
            <MoneyFormat :amount="cumulatedAmount" currency="EUR" />
          </strong>
        </span>
        <apexchart
          v-if="chartSeries[0].data.some((value) => value !== 0)"
          type="bar"
          :options="chartOptions"
          :series="chartSeries"
        ></apexchart>
      </section>
    </aside>
  </div>
</template>

<script setup>
import { onMounted, ref, computed, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useUserStore } from '../stores/user.js';
import { useOrderStore } from '../stores/orders.js';
import { useNotificationStore } from '../stores/notifications.js';
import { kyWithAuth } from '../libs/ky';
import apexchart from 'vue3-apexcharts';
import IconLibrary from '../components/Shared/IconLibrary.vue';
import { format } from 'date-fns';
import MoneyFormat from '../components/Shared/MoneyFormat.vue';

const rankedProducts = ref([]);
const topBuyers = ref([]);
const sellerRanking = ref([]);
const directorRanking = ref([]);
const monthlyTotals = ref(new Array(12).fill(0));
const productPeriod = ref('last_3_months');
const buyerPeriod = ref('last_3_months');
const sellerPeriod = ref('last_3_months');
const directorPeriod = ref('last_3_months');
const cumulatedAmount = ref(0);

const userStore = useUserStore();
const orderStore = useOrderStore();
const notificationStore = useNotificationStore();
const notifications = computed(() => notificationStore.notifications);

const route = useRoute();
const router = useRouter();

const calculateCumulatedAmount = () => {
  const currentMonth = new Date().getMonth();
  cumulatedAmount.value = monthlyTotals.value.slice(0, currentMonth + 1).reduce((sum, value) => {
    const numValue = Number(value);
    return sum + (isNaN(numValue) ? 0 : numValue);
  }, 0);
};

const markAsRead = (notificationId, productId) => {
  notificationStore.markAsRead(notificationId);
  router.push({ name: 'Product', params: { product_id: productId } });
};

const fetchRankedProducts = async () => {
  try {
    const { bestSellers } = await kyWithAuth
      .get('statistics/best-sellers', {
        searchParams: { period: productPeriod.value }
      })
      .json();

    rankedProducts.value = bestSellers.map((prod) => ({
      variant_id: prod._id,
      product_id: prod.product_id,
      name: prod.name,
      options: prod.options,
      image: prod.image,
      quantity: prod.totalQuantity
    }));
  } catch (e) {
    console.error(e);
  }
};

const fetchTopBuyers = async () => {
  try {
    const { topBuyers: buyers } = await kyWithAuth
      .get('statistics/top-buyers', {
        searchParams: { period: buyerPeriod.value }
      })
      .json();

    topBuyers.value = buyers;
  } catch (e) {
    console.error(e);
  }
};

const fetchSellerRanking = async () => {
  try {
    const { sellerRanking: ranking } = await kyWithAuth
      .get('statistics/seller-ranking', {
        searchParams: { period: sellerPeriod.value }
      })
      .json();

    sellerRanking.value = ranking;
  } catch (e) {
    console.error(e);
  }
};

const fetchDirectorRanking = async () => {
  try {
    const { directorRanking: ranking } = await kyWithAuth
      .get('statistics/director-ranking', {
        searchParams: { period: directorPeriod.value }
      })
      .json();

    directorRanking.value = ranking;
  } catch (e) {
    console.error(e);
  }
};

const formatDate = (dateString) => {
  return format(new Date(dateString), 'dd/MM/yyyy');
};

onMounted(async () => {
  try {
    if (!notificationStore.notifications.length && userStore?.user?.role === 'director') {
      await notificationStore.fetchNotifications();
    }
    if (userStore.isLogged) {
      await Promise.all([
        fetchRankedProducts(),
        fetchTopBuyers(),
        userStore?.user?.role === 'director' ? fetchSellerRanking() : Promise.resolve(),
        userStore?.user?.role === 'seller' ? fetchDirectorRanking() : Promise.resolve()
      ]);
      const { totalOrders } = (await kyWithAuth.get('statistics/total-orders').json()) || [];

      totalOrders.forEach((order) => {
        monthlyTotals.value[order._id - 1] = Number(order.totalAmount.toFixed(0));
      });

      calculateCumulatedAmount();
    }
  } catch (e) {
    console.error('Error fetching data:', e);
  }
});

const chartOptions = computed(() => ({
  fill: {
    colors: ['#D6DAFF', '#E91E63']
  },
  states: {
    hover: {
      filter: {
        type: 'darken',
        value: 0.7
      }
    }
  },
  xaxis: {
    categories: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ],
    labels: {
      rotate: -45,
      rotateAlways: true,
      hideOverlappingLabels: true,
      showDuplicates: false,
      trim: false,
      style: {
        fontSize: '11px'
      }
    },
    tickPlacement: 'on'
  },
  chart: {
    width: '100%',
    toolbar: {
      show: false
    }
  },
  yaxis: {
    show: false
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: '60%',
      borderRadius: 3,
      borderRadiusApplication: 'end'
    }
  },
  dataLabels: {
    enabled: false
  },
  stroke: {
    show: true,
    width: 2,
    colors: ['transparent']
  },
  tooltip: {
    enabled: true
  },
  responsive: [
    {
      breakpoint: 480,
      options: {
        chart: {
          width: '100%'
        }
      }
    }
  ]
}));

const chartSeries = computed(() => [
  {
    name: 'Total (€)',
    data: monthlyTotals.value
  }
]);

onMounted(() => {
  isUserComeFromVerifyMail();
});

const isUserComeFromVerifyMail = () => {
  if (route.redirectedFrom?.path?.includes('/verify-email')) {
    if (userStore.isMailVerified) {
      console.log('successfully verify mail');
    } else {
      console.log('verify mail failed');
    }
  }
};

const buyersWithPercentage = computed(() => {
  if (topBuyers.value.length === 0) return [];
  const maxAmount = topBuyers.value[0].totalAmount;
  return topBuyers.value.map((buyer) => ({
    ...buyer,
    percentage: (buyer.totalAmount / maxAmount) * 100
  }));
});

const sellersWithPercentage = computed(() => {
  if (sellerRanking.value.length === 0) return [];
  const maxAmount = sellerRanking.value[0].totalAmount;
  return sellerRanking.value.map((seller) => ({
    ...seller,
    percentage: (seller.totalAmount / maxAmount) * 100
  }));
});

const directorsWithPercentage = computed(() => {
  if (directorRanking.value.length === 0) return [];
  const maxAmount = directorRanking.value[0].totalAmount;
  return directorRanking.value.map((director) => ({
    ...director,
    percentage: (director.totalAmount / maxAmount) * 100
  }));
});

const getProductRoute = (id) => `/products/${id}`;

// Watch for changes in monthlyTotals
watch(
  monthlyTotals,
  () => {
    calculateCumulatedAmount();
  },
  { deep: true, immediate: true }
);

const exportCSV = async (type) => {
  try {
    let period;
    switch (type) {
      case 'products':
        period = productPeriod.value;
        break;
      case 'buyers':
        period = buyerPeriod.value;
        break;
      case 'sellers':
        period = sellerPeriod.value;
        break;
      case 'directors':
        period = directorPeriod.value;
        break;
    }

    const response = await kyWithAuth.get(`statistics/export-${type}`, {
      searchParams: { period },
      responseType: 'blob'
    });

    const blob = await response.blob();
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${type}_export_${period}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  } catch (error) {
    console.error(`Error exporting ${type} CSV:`, error);
  }
};
</script>

<style lang="scss" scoped>
#page {
  display: grid;
  justify-content: space-between;
  grid-template-columns: 1fr 340px;
}

main {
  position: relative;
}

.boxed + .boxed {
  @include margin(sm, top);
}

.tag {
  padding: 2px 8px;
  background-color: coral;
  color: white;
  border-radius: $base-radius;
  font-size: 80%;
  font-weight: $font-weight-bold;
}

.illustration {
  position: absolute;
  top: -45px;
  right: -45px;
  z-index: 1;
  opacity: 0.25;
}

%ranking-section {
  margin-top: 20px;
}

%ranking-header {
  display: grid;
  align-items: center;
  border-radius: $base-radius;
  gap: 20px;
  padding: 8px 15px;
  background-color: $snow;
  margin: 25px 0 5px;
  color: $monsoon;
  font-weight: $font-weight-light;
  font-size: 90%;
  span:last-child {
    text-align: right;
  }
}

%ranking-item {
  display: grid;
  align-items: center;
  gap: 10px;
  padding: 10px;
  position: relative;
  overflow: hidden;
  border-radius: $base-radius;

  &:nth-child(even) {
    background-color: #f9f9f9;
  }

  & + & {
    margin-top: 5px;
  }

  > * {
    position: relative;
    z-index: 2;
  }

  span:first-child {
    font-weight: $font-weight-bold;
  }

  span:last-child {
    text-align: right;
    font-weight: $font-weight-bold;
  }
}

.product-ranking {
  .ranking-header {
    @extend %ranking-header;
    grid-template-columns: 50px 1fr 90px 30px;
  }

  .product-list {
    contain: content;
  }

  .product {
    display: grid;
    align-items: center;
    grid-template-columns: 50px 1fr 90px 30px;
    gap: 20px;
    padding: 3px 15px;
    border-radius: 4px;
    text-decoration: none;
    background-color: transparent;
    color: inherit;
    transition: background-color 0.2s ease;
    transform: translateZ(0);
    backface-visibility: hidden;
  }

  .product img {
    width: 42px;
    height: 42px;
    object-fit: cover;
    border-radius: 50%;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.15);
  }

  .product p {
    margin: 0;
  }

  .product .arrow {
    position: relative;
    right: 0;
    transition: right 0.2s ease;
    transform: translateZ(0);
  }

  .product:hover {
    background-color: #f5f5f5;
  }

  .product:hover .arrow {
    right: -12px;
  }

  .product + .product {
    margin-top: 5px;
  }

  @media (hover: hover) {
    .product {
      will-change: background-color;
    }

    .product .arrow {
      will-change: right;
    }
  }
}

.buyer-ranking,
.seller-ranking,
.director-ranking {
  @extend %ranking-section;

  .ranking-header {
    @extend %ranking-header;
  }
}

.buyer-ranking {
  .ranking-header,
  .buyer {
    grid-template-columns: 2fr 1fr 1fr 1fr;
  }

  .buyer {
    @extend %ranking-item;
  }
}

.seller-ranking {
  .ranking-header,
  .seller {
    grid-template-columns: 2fr 1fr 1fr;
  }

  .seller {
    @extend %ranking-item;
  }
}

.director-ranking {
  @extend %ranking-section;

  .ranking-header,
  .director {
    grid-template-columns: 2fr 1fr 1fr;
  }

  .director {
    @extend %ranking-item;
  }
}

.fill-bar {
  position: absolute !important;
  top: 0;
  left: 0;
  height: 100%;
  background-color: rgba($brand-blue, 0.1);
  z-index: 1;
}

.buyer-ranking .buyer:nth-child(even) .fill-bar,
.seller-ranking .seller:nth-child(even) .fill-bar,
.director-ranking .director:nth-child(even) .fill-bar {
  background-color: rgba($brand-blue, 0.075);
}

img.round {
  width: 42px;
  height: 42px;
  object-fit: cover;
  border-radius: 100px;
  overflow: hidden;
  border: solid 1px transparent;
  box-shadow: 2px 2px 8px rgba($black, 0.15);
}

.notifications.boxed {
  padding: 1rem;
  a {
    padding: 0.5rem 1rem;
  }
}

.notification-center {
  padding: 1.75rem 2rem 1.25rem;

  header {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .message {
    display: flex;
    align-items: center;
    gap: 1rem;

    img {
      width: 50px;
    }
  }

  .notification {
    font-size: 0.95rem;
    margin-top: 1.75rem;

    &.is-read {
      opacity: 0.35;
    }
  }

  .btn {
    margin-top: 0.5rem;
  }
}

nav {
  position: relative;
  z-index: 5;
  display: flex;
  flex-direction: column;

  > a {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @include padding(xs, top bottom left);
    border-radius: $base-radius;
    text-decoration: none;
    background-color: transparent;
    color: inherit;
    transition: background-color $base-speed-normal ease;

    span {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }

    + div {
      border-top: 1px solid $gainsboro;
    }

    > .arrow {
      margin-left: auto;
      transition: transform $base-speed-normal ease;
    }

    &:hover {
      background-color: $snow;
      .arrow {
        transform: translateX(10px);
      }
    }
  }
}

select {
  padding: 4px 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: white;
  font-size: 14px;
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    display: flex;
    gap: 10px;
  }
}

@media (max-width: 767px) {
  #page {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  main + aside {
    @include margin(sm, left right);
    border-radius: $base-radius;
  }

  .product-ranking {
    display: none;
  }

  .illustration {
    right: -100px;
  }

  .apexcharts-canvas {
    width: 100% !important;
  }
}
</style>
