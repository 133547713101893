<template>
  <div>
    <div
      class="dropzone-container"
      @dragover.prevent="isDragging = true"
      @dragleave="isDragging = false"
      @drop="handleDrop"
      v-if="!files.length"
    >
      <input
        type="file"
        :multiple="allowMultiple"
        ref="fileInput1"
        id="fileInput1"
        class="hidden-input"
        @change="handleFiles"
        :accept="accept"
      />
      <label for="fileInput1" class="file-label">
        <div v-if="isDragging">{{ $t('forms.upload.drop_files') }}</div>
        <div v-else>{{ $t('forms.upload.drag_or_click') }}</div>
      </label>
    </div>
    <div
      v-else-if="isImageUpload"
      class="preview-container"
      :class="{ 'allow-multiple': allowMultiple }"
    >
      <div v-for="(image, index) in files" :key="index" class="preview-card">
        <img class="preview-img" :src="getImageSrc(image)" />
        <div class="btn btn-ghost btn-delete" @click="removeFile(index)">
          <IconLibrary name="delete" color="red" size="xs" :hasMargin="false" />
        </div>
      </div>
      <div class="preview-card" v-if="allowMultiple && files.length < maxFiles">
        <div
          class="dropzone-container dropzone-container-square"
          @dragover.prevent="isDragging = true"
          @dragleave="isDragging = false"
          @drop="handleDrop"
        >
          <input
            type="file"
            multiple
            ref="fileInput2"
            id="fileInput2"
            class="hidden-input"
            @change="handleFiles"
            :accept="accept"
          />
          <label for="fileInput2" class="file-label">
            <div v-if="isDragging">{{ $t('forms.upload.drop_files') }}</div>
            <div v-else>{{ $t('forms.upload.drag_or_click') }}</div>
          </label>
        </div>
      </div>
    </div>
    <div v-else class="file-preview">
      <div class="file-info">
        <span class="file-name">{{ files[0].name }}</span>
        <button class="btn btn-ghost btn-sm" @click="removeFile(0)">
          <IconLibrary name="delete" color="red" size="xs" :hasMargin="false" />
        </button>
      </div>
    </div>
    <p v-if="errorMessage" class="error-message">{{ $t(errorMessage) }}</p>
  </div>
</template>

<script setup>
import { ref, watch, computed } from 'vue';
import IconLibrary from '../IconLibrary.vue';

const props = defineProps({
  modelValue: Array,
  allowMultiple: { type: Boolean, default: true },
  maxFiles: { type: Number, default: 8 },
  accept: { type: String, default: '.jpg,.jpeg,.png,.webp' }
});
const emit = defineEmits(['update:modelValue']);

const isDragging = ref(false);
const files = ref([]);
const errorMessage = ref('');

const MAX_FILE_SIZE = 2.5 * 1024 * 1024; // 2.5MB in bytes

const isImageUpload = computed(() => {
  return (
    props.accept.includes('image') ||
    ['.jpg', '.jpeg', '.png', '.webp'].some((ext) => props.accept.includes(ext))
  );
});

const getImageSrc = (file) => {
  if (typeof file === 'string') {
    return file;
  } else {
    return URL.createObjectURL(file);
  }
};

const validateFiles = (newFiles) => {
  errorMessage.value = '';

  if (newFiles.length + files.value.length > props.maxFiles) {
    errorMessage.value = 'forms.upload.error_max_files';
    return false;
  }

  const oversizedFiles = newFiles.filter((file) => file.size > MAX_FILE_SIZE);
  if (oversizedFiles.length > 0) {
    errorMessage.value = 'forms.upload.error_file_size';
    return false;
  }

  return true;
};

const handleFiles = (e) => {
  let newFiles = Array.from(e.target.files || e.dataTransfer.files);
  const allowedTypes = props.accept.split(',').map((type) => type.trim());
  newFiles = newFiles.filter((file) => {
    const extension = '.' + file.name.split('.').pop().toLowerCase();
    return allowedTypes.includes(extension);
  });

  if (!validateFiles(newFiles)) {
    return;
  }

  if (!props.allowMultiple) {
    newFiles = newFiles.slice(0, 1);
  } else if (props.maxFiles) {
    const totalAllowedFiles = props.maxFiles - files.value.length;
    newFiles = newFiles.slice(0, totalAllowedFiles);
  }

  const updatedFiles = [...files.value, ...newFiles];
  files.value =
    updatedFiles.length > props.maxFiles ? updatedFiles.slice(0, props.maxFiles) : updatedFiles;
  emit('update:modelValue', files.value);
};

const handleDrop = (e) => {
  e.preventDefault();
  isDragging.value = false;
  handleFiles(e);
};

const removeFile = (index) => {
  files.value.splice(index, 1);
  emit('update:modelValue', files.value);
};

watch(
  () => props.modelValue,
  (newVal) => {
    files.value = newVal || [];
  },
  { deep: true, immediate: true }
);
</script>

<style scoped lang="scss">
.dropzone-container {
  padding: 50px;
  background: $brand-light-grey;
  border-radius: $base-radius;
  border: 1px dashed $brand-light-purple;
  text-align: center;
  &:hover {
    border: 1px dashed $brand-grey;
    cursor: pointer;
  }
  &-square {
    padding: 8px;
    aspect-ratio: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 90%;
  }
}
.hidden-input {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
}
.file-label {
  display: block;
  cursor: pointer;
  font-size: 90%;
  font-style: italic;
  color: $brand-grey;
  u {
    color: $brand-indigo;
  }
}
.preview-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  margin-top: 0px;
  margin-bottom: 30px;
  &.allow-multiple {
    grid-template-columns: repeat(4, 1fr);
  }
}

.preview-card {
  position: relative;
  .btn {
    position: absolute;
    top: -10px;
    left: -10px;
    background-color: $white;
    &:hover {
      background-color: $white;
    }
  }
}

.preview-container > :first-child {
  grid-column: 1 / span 2;
  grid-row: 1 / span 2;
}

.preview-img {
  width: 100%;
  height: 100%;
  aspect-ratio: 1/1;
  border-radius: $base-radius;
  box-shadow: 2px 2px 8px rgba($black, 0.15);
  object-fit: cover;
}

.error-message {
  color: red;
  margin-top: 10px;
  font-size: 14px;
}

.file-preview {
  margin: 10px 0;
  border: 1px solid $brand-light-purple;
  border-radius: $base-radius;
}

.file-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  background: var(--color-background-alt);
  border-radius: 6px;
}

.file-name {
  font-size: 0.9em;
  color: var(--color-text);
}
</style>
